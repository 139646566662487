/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
const $ = require("jquery");

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require("bootstrap");

import "./css/app.scss";
import "./styles/app.css";
import "./css/style.css";
import "./js/bootstrap-date-time-picker/bootstrap-date-time-picker/bootstrap5/css/bootstrap-datetimepicker.min.css"
// start the Stimulus application
// import "bootstrap";

import "bootstrap/js/dist/util";
import "./js/slick.min.js";
import "./js/jquery.magnific-popup.min.js";
//import "./js/zangdar.min.js";
import "./js/bootstrap-date-time-picker/bootstrap-date-time-picker/bootstrap5/js/bootstrap-datetimepicker"

import { Calendar } from  '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import 'bootstrap-icons/font/bootstrap-icons.css'; // webpack uses file-loader to handle font files

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');



$('#member_officer_collection_memberOfficers_0_translations_th_position').val("การตลาด / ฝ่ายขาย");
$('#member_officer_collection_memberOfficers_0_translations_en_position').val("การตลาด / ฝ่ายขาย");

$('#member_officer_collection_memberOfficers_1_translations_th_position').val("ฝ่ายจัดซื้อ");
$('#member_officer_collection_memberOfficers_1_translations_en_position').val("ฝ่ายจัดซื้อ");

$('#member_officer_collection_memberOfficers_2_translations_th_position').val("ฝ่ายการเงิน");
$('#member_officer_collection_memberOfficers_2_translations_en_position').val("ฝ่ายการเงิน");



document.addEventListener('DOMContentLoaded', function() {
  var calendarEl = document.getElementById('calendar');
  
  var startDate1 = document.getElementById('starDate1'); 
  var endDate1 = document.getElementById('endDate1');
  var title1 = document.getElementById('title1');  
   

  var startDate2 = document.getElementById('starDate2'); 
  var endDate2 = document.getElementById('endDate2');
  var title2 = document.getElementById('title2');  
  

  var startDate3 = document.getElementById('starDate3'); 
  var endDate3 = document.getElementById('endDate3');
  var title3 = document.getElementById('title3');  


  var startDate4 = document.getElementById('starDate4'); 
  var endDate4 = document.getElementById('endDate4');
  var title4 = document.getElementById('title4');  

  var startDate5 = document.getElementById('starDate5'); 
  var endDate5 = document.getElementById('endDate5');
  var title5 = document.getElementById('title5');  

  var startDate6 = document.getElementById('starDate6'); 
  var endDate6 = document.getElementById('endDate6');
  var title6 = document.getElementById('title6');  
  
  var dataItem;
  var dataItem2;
  var dataItem3;
  var dataItem4;
  var dataItem5;
  var dataItem6;

  var jsons = new Array();

  if(startDate1 != null) {

    var endObject = new Date($(endDate1).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);

    dataItem  = {
    "title":$(title1).val(), 
    "start":$(startDate1).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};

    jsons.push(dataItem);

  }


  if(startDate2 != null) {

    var endObject = new Date($(endDate2).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);

    dataItem2  = {
    "title":$(title2).val(), 
    "start":$(startDate2).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};

    jsons.push(dataItem2);
  }


  if(startDate3 != null) {

    var endObject = new Date($(endDate3).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);
   
    dataItem3  = {
    "title":$(title3).val(), 
    "start":$(startDate3).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};

    jsons.push(dataItem3);
  }


  if(startDate4 != null) {

    var endObject = new Date($(endDate4).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);

    dataItem4  = {
    "title":$(title4).val(), 
    "start":$(startDate4).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};

    jsons.push(dataItem4);
  }

  if(startDate5 != null) {

    var endObject = new Date($(endDate5).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);


    dataItem5  = {
    "title":$(title5).val(), 
    "start":$(startDate5).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};
  
    jsons.push(dataItem5);
  }
  
  if(startDate6 != null) {

    var endObject = new Date($(endDate6).val());
    endObject.setDate(endObject.getDate() + 1);
    var end = endObject.toISOString().slice(0, 10);


    dataItem6  = {
    "title":$(title6).val(), 
    "start":$(startDate6).val() , 
    "end":end,
    "overlap": false,
    "display": 'background',
    "color": '#F26623'};
  
    jsons.push(dataItem6);
  }



  var calendar = new Calendar(calendarEl, {
    plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrap5Plugin ],
    themeSystem: 'bootstrap5',
    events: '/th/ajax/events',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth'
    },
    eventClick: function(info) {
      window.open(info.event.url,"event")
      // alert('Event: ' + info.event.title);
      // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      // alert('View: ' + info.view.type);
  
      // change the border color just for fun
      // info.el.style.borderColor = 'red';
    },
    themeSystem: 'bootstrap5',
    events: jsons
  });
  
  calendar.render();
  $('.fc-event-title').addClass('text-black');
});

$('label[for=member_officer_profile_translations_en_position]').addClass('d-none');
$('#member_officer_profile_translations_en_position').addClass('d-none');

$(function(){


  $('#verifyInfo').click(function () { 
    
    $(this).attr('disabled','disalbed');

    $('#notiftEmailAlertSuccess').addClass('d-none');
    $('#notiftEmailAlertFail').addClass('d-none');
    $.ajax({
      type: "get",
      url: "/sendmail/member/" + $("#memberId").val(),
      crossDomain: true,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      success: function (data) {
      //  console.log(data);
      if(data == true)
      { 
        $('#notiftEmailAlertSuccess').removeClass('d-none');
       
      }else{
        $('#notiftEmailAlertFail').removeClass('d-none');
      }

      setTimeout(
        function() 
        {
          $('#verifyInfo').attr('disabled',false);
          $('#notiftEmailAlertSuccess').addClass('d-none');
          $('#notiftEmailAlertFail').addClass('d-none');
          //do something special
        }, 5000);

        return false;
      },
    });

  });


  $("#member_officer_profile_translations_th_position").change(function () {
    $("#member_officer_profile_translations_en_position").val($("#member_officer_profile_translations_th_position").val());
  });

 // if(typeof $('#member_officer_profile_translations_en_position') === 'defined'){
 //   alert('');

//  }

  //alert('hello');

  $("#btnCollapse").click(function () { // alert($('#collapseExample').hasClass('.collapse'));

    if ($('#collapseExample').hasClass('collapse')) {
    
    $('#collapseExample').removeClass('collapse');
    $('#collapseExample').addClass('collapsed');
    
    } else {
    $('#collapseExample').addClass('collapse');
    $('#collapseExample').removeClass('collapsed');
    }
    
    });
   
    $("#qAllMember").on('keyup touchend', function(){
      setTimeout(function() {
     
        $('#memberForm').submit();
        }, 5000);

     
    });

  $("#qMat").on('keyup touchend', function(){
    $('#matSearch').submit();
  });

  $("#qCluster").on('keyup touchend', function(){
    $('#clusterSearch').submit();
  });

    $('.js-datepicker').datetimepicker({
      pickDate: false,
      pickSeconds: false,
      pick12HourFormat: false  ,
      format: 'yyyy-mm-dd hh:i:ss'
      
  });
  
  if($('.js-datepicker').length > 0) {
  $('.js-datepicker').each(function(index)
  {
    var dt = $(this).val();
    dt = dt.replace("T"," ");
  
    $(this).val(dt);
  });
  }
  
 
  
  $(document).on('click','.js-remove-collection',function(){

    //   e.preventDefault();
      //  alert('tes1t');
        var removeItem = $(this).data("remove");
        $(removeItem)
            .fadeOut()
            .remove();
 
          
        return false;
    });
    
    $(".js-collect-add").click(function (e) {
   
      var c  = $(this).data('add-item');
      var prototype = $(c).data('prototype');
      var index = $(c).data('index');
      // Replace '__name__' in the prototype's HTML to
      // instead be a number based on how many items we have
      var newForm = prototype.replace(/__name__/g, index);
      // increase the index with one for the next item
      $(c).data('index', index + 1);
      // Display the form in the page before the "new" link
      $(this).before(newForm);

      $('#panel-collection-production_process_profile_companyProductionProcesses').html();

      return false
});
 
 
 
 
$(".js-purchase").click(function (e) {
   
  var c  = $(this).data('add-item');
  var id = $(this).data('id');
  
  if ($('#'+id).length > 0) {
    $("input[name='qty["+id+"]']").focus();
    return false;
  }
  $('#purchaseItem').append(c);

  $("input[name='qty["+id+"]']").on('keyup touchend', function(){
    
    var qty =  parseFloat($(this).val());
    
    if(qty == 0)
    {
      $(this).val(1);
      qty = 1;
    }
 
 
    var price = parseFloat($(this).data('price'));
    var totalPrice = qty * price;
    var xx = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    $("span[data-id='"+id+"']").html(xx.format(totalPrice));
    grandTotal();
    });
 
 
    $(".js-remove-purchase").click(function (e) {
      //   e.preventDefault();
    
          var removeItem = $(this).data("remove");
          $('#'+removeItem)
              .fadeOut()
              .remove();
    
          grandTotal();
          return false;
      });
 
 var jsNo = $('.jsNo');
 
 jsNo.each(function( index ) {
    $(this).html(index+1);
    //console.log(index);
 });
 
 grandTotal();
 
  
  return false
});
$("#purchaseForm").on("submit", function(){
  

  var grandTotal = $('#grandTotal').html();

 
  if(grandTotal == 0)
  { 
    $('#ticketAlert').addClass('show');
    setTimeout(function() {
     
      $('#ticketAlert').removeClass("show");
      }, 5000);

    return false;
  }else{

    $(this).submit();
    return false;
  }
  

});

function isEmpty(value) {
  return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
}

function grandTotal()
{ 
  var grandTotal =0;
  $('.totalPrice').each(function( index ) {
     var totalPrice = $(this).html();
     totalPrice = parseFloat(totalPrice.replace(/,/g, ''));
     
     grandTotal += totalPrice;
  });

  
  var xx = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  $('#grandTotal').html(xx.format(grandTotal));
  

}

  $(".btnTaxNo").click(function (e) {
    e.preventDefault();
   // $('#memberHas').val();  
  
   $('#result-tax').html('');
   var value = $('#taxNo').val();
   if( value.length < 13 )
   {
    $('#displayInfo').addClass('d-none');
    $('#result-tax').append("&nbsp; <i class='fa fa-times text-danger'></i> กรุณากรอกตัวเลขให้ครบ 13 หลัก");
    return false;
   } 
   $('#result-tax').html('');
   // $('#memberId').val('');
  //      $('#companyNameTH').removeAttr('disabled');
        $('#companyNameTH').html('');
  //      $('#companyNameEN').removeAttr('disabled');
        $('#companyNameEN').html('');
  //      $('#companyAddressTH').removeAttr('disabled');
        $('#companyAddressTH').html('');
   //     $('#companyAddressEN').removeAttr('disabled');
        $('#companyAddressEN').html('');
   //     $('#tel').removeAttr('disabled');
        $('#tel').html('');
    //    $('#email').removeAttr('disabled');
        $('#email').html('');
    //    $('#website').removeAttr('disabled');
        $('#website').html('');

    $.ajax({
      type: "get",
      url: "/findMember/?value=" + $("#taxNo").val(),
      crossDomain: true,
      headers: {
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      success: function (data) {
      //  console.log(data);
      if(data != null)
      { $('#result-tax').html('');
        $('#companyNameTH').html(data.companyNameTH);
   //     $('#companyNameTH').attr('disabled','disabled');
        $('#companyNameEN').html(data.companyNameEN);
    //    $('#companyNameEN').attr('disabled','disabled');
        $('#companyAddressTH').html(data.companyAddressTH);
    //    $('#companyAddressTH').attr('disabled','disabled');
        $('#companyAddressEN').html(data.companyAddressEN);
    //    $('#companyAddressEN').attr('disabled','disabled');
        $('#tel').html(data.tel);
     //   $('#tel').attr('disabled','disabled');
        const email = data.email.split("@",2);
        var first = email[0].slice(0,1);
        var last =  email[0].substr(email[0].length - 1);
        var dokjun = "";
        for (let index = 0; index < (email[0].length-2); index++) {
          
          dokjun += "*";
          
        }

        var contactEmail = first + dokjun + last + "@" + email[1];
       // console.log("first ="+first);
       // console.log("dokjub = "+dokjun);
      //  console.log("last = "+last);
     
        
        $('#email').html(contactEmail);
     //   $('#email').attr('disabled','disabled');
        $('#website').html(data.website);
      //  $('#website').attr('disabled','disabled');
        $('#displayInfo').removeClass('d-none');
        $('#memberId').html(data.id);
        $('#result-tax').append("&nbsp; <i class='fa fa-check text-success'></i> พบข้อมูล")
        $('#memberNotFound').html('');
        $('#goToRegister').addClass('d-none');
      }else{
        $('#displayInfo').addClass('d-none');
        $('#result-tax').html('');
        $('#result-tax').append("&nbsp; <i class='fa fa-times text-danger'></i> ไม่พบข้อมูลจากฐานข้อมูล")
        $('#memberId').val(); 
        $('#memberNotFound').html('ต้องการทำการสมัครสมาชิกหรือไม่?');
        $('#goToRegister').removeClass('d-none');
      }
        return false;
      },
    });
  });

  
  $('#taxNo').on('paste', function (event) {
    if (event.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
      event.preventDefault();
    }
  });
  
  $("#taxNo").on("keypress",function(event){
                  if(event.which < 48 || event.which >58){
                      return false;
                  }
              });

  $( document ).ready(function() {

   /* const wizard = new window.Zangdar("#wizard", {
      onStepChange: () => {

       
        onStepChange(wizard, "steps-native");
      },
      onSubmit(e) {
        // e.preventDefault()
        // console.log(e.target.elements)
      
        //return false
      },
    });

    function buildStepsBreadcrumb(wizard, element, steps) {
      const $steps = document.getElementById(element);
      $steps.innerHTML = "";
      for (let label in steps) {
        if (steps.hasOwnProperty(label)) {
          const $li = document.createElement("li");
          const $a = document.createElement("a");
          $li.classList.add("nav-item");
          $li.classList.add("col-6");
          $a.classList.add("w-100");
          $a.classList.add("btn");
          //  $a.classList.add('border')
          $a.classList.add("border-0");
          if (steps[label].active) {
            $a.classList.add("active");
          }
          $a.setAttribute("href", "#");
          $a.innerText = label;
          $a.addEventListener("click", (e) => {
            e.preventDefault();
            wizard.revealStep(label);
          });
          $li.appendChild($a);
          $steps.appendChild($li);
        }
      }
    }
  
    function onStepChange(wizard, selector) {
      const steps = wizard.getBreadcrumb();
      buildStepsBreadcrumb(wizard, selector, steps);
    }

    onStepChange(wizard, "steps-native");

    
    
*/
  });
 
 

  $("#cateSearch").change(function () {
    $("#formSearch").submit();
  });

  $("#cate").change(function () {
    $("#matSearch").submit();
  });

  $("#cateCluster").change(function () {
    $("#clusterSearch").submit();
  });

  $("#industryCategoryList").change(function () {
   
    $("#searchProductList").submit();
  });

  $("#categoryProductionProcess").change(function () {
   
    $("#searchProductList").submit();
  });


  $("#industryCategoryList").change(function () {
   
    $("#searchProductList").submit();
  });

  $("#categoryProductionProcess").change(function () {
   
    $("#searchProductList").submit();
  });

  $("#industryCategoryListAll").change(function () {
   
    $("#searchProductAll").submit();
  });

  $("#categoryProductionProcessAll").change(function () {
   
    $("#searchProductAll").submit();
  });

  $("#memberQuery").change(function () {
   
    $("#searchProductAll").submit();
  });

  $("#typeQuery").change(function () {
   
    $("#searchProductAll").submit();
  });
  $("#clusterGroupSearch").change(function () {
    $("#cateCluster").val("");
    $("#clusterSearch").submit();
  });

  $("span.page-link,a.page-link").each(function () {
    var text = $(this).text();

    text = text.replace("Previous", "");
    text = text.replace("Next", "");

    $(this).text(text);
  });

  $(".multiple-items").slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    prevArrow:
      '<div class="slider-arrow slider-prev fa fa-angle-left text-theme"></div>',
    nextArrow:
      '<div class="slider-arrow slider-next fa fa-angle-right  text-theme"></div>',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  });

  // Magnify activation
  $(".portfolio-item").magnificPopup({
    delegate: "a",
    type: "image",
    gallery: { enabled: true },
  });

  //product-slider-box
  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  });
  $(".slider-nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    asNavFor: ".slider-for",
    dots: false,
    arrows: true,
    prevArrow: '<div class="slider-arrow slider-prev fa fa-angle-up"></div>',
    nextArrow: '<div class="slider-arrow slider-next fa fa-angle-down"></div>',
    focusOnSelect: true,
    verticalSwiping: true,
  });

  $(".slide-sponsor").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    dots:false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
       {
           breakpoint: 1920 ,
           settings: {
             slidesToShow: 3,
             slidesToScroll: 1,
         
           }
         },
       {
           breakpoint: 900,
           settings: {
             slidesToShow: 2,
             slidesToScroll: 1,
          
           }
         },
       {
           breakpoint: 550,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
           
           }
         }
       ]

  });

  // Banner 2 start initialization.
  if ($(document).find(".slide").length > 0) {
    function sliderPluggin(activeslide = 0) {
      const slides = document.querySelectorAll(".slide");
      slides[activeslide].classList.add("active");
      function clearActiveClasses() {
        slides.forEach((slide) => {
          slide.classList.remove("active");
        });
      }

      for (const slide of slides) {
        slide.addEventListener("click", () => {
          clearActiveClasses();
          slide.classList.add("active");
        });
      }
    }

    sliderPluggin(0);
  }
});
